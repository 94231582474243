import React, { useEffect } from 'react';
import { Container, Typography, Box, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledContainer = styled(Container)({
    marginTop: '100px',
    marginBottom: '50px'
});

const PaymentPage = () => {
    useEffect(() => {
        // PayPal 버튼 초기화
        if (window.paypal) {
            window.paypal.HostedButtons({
                hostedButtonId: "K84KGRBHVYP6U"
            })
            .render("#paypal-container-K84KGRBHVYP6U");
        }
    }, []); // 컴포넌트 마운트 시 한 번만 실행

    return (
        <StyledContainer maxWidth="md">
            <Paper elevation={3} sx={{ padding: 4 }}>
                <Typography variant="h4" gutterBottom align="center" sx={{ mb: 4 }}>
                    서비스 구독하기
                </Typography>
                
                <Box sx={{ mb: 4 }}> 
                    <Typography variant="body1" sx={{ mb: 2 }}>
                        • 다양한 커스터마이징 옵션
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 2 }}>
                        • 24/7 기술 지원
                    </Typography>
                </Box>

                <Box sx={{ 
                    width: '100%', 
                    display: 'flex', 
                    justifyContent: 'center',
                    mt: 4 
                }}>
                    <div id="paypal-container-K84KGRBHVYP6U"></div>
                </Box>
            </Paper>
        </StyledContainer>
    );
};

export default PaymentPage; 