// GuestbookViewer.js
import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom'; // useParams를 사용하여 workspaceId를 가져옴
import {deleteMessage, getWorkspaceSettings, subscribeToMessages} from './firebaseService';
import {Box, Card, CardContent, IconButton, Modal, Typography} from '@mui/material';
import { useSwipeable } from 'react-swipeable';
import { QRCodeCanvas } from 'qrcode.react';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import CloseIcon from '@mui/icons-material/Close';
import {DeleteOutline} from "@mui/icons-material";

const halloweenColors = [
    '#fdfddb'
];

const getPantoneColor = (index) => {
    return halloweenColors[index % halloweenColors.length];
};

const GuestbookViewer = () => {
    const viewerRef = useRef(null); // 전체 화면 대상 Ref
    const [isFullscreen, setIsFullscreen] = React.useState(false);
    const { workspaceId } = useParams(); // URL에서 workspaceId 추출
    const [workspaceName, setWorkspaceName] = useState('');
    const [messages, setMessages] = useState([]);

    const [open, setOpen] = useState(false); // Modal 상태
    const [selectedMessage, setSelectedMessage] = useState(null); // 선택된 메시지

    const [backgroundImage, setBackgroundImage] = useState('');
    const [logo, setLogo] = useState('');
    const [isMessageBoardOpen, setIsMessageBoardOpen] = useState(true); // 메시지보드 상태

    const endOfMessagesRef = useRef(null);
    const containerRef = useRef(null);
    const isDragging = useRef(false);
    const startX = useRef(0);
    const scrollLeft = useRef(0);
    const previousMessageCount = useRef(0);
    const audioRef = useRef(null);

    const [activeIndex, setActiveIndex] = useState(0); // 현재 보여지는 카드의 인덱스
    const timerRef = useRef(null);

    const urlParams = new URLSearchParams(window.location.search);
    const isAdmin = urlParams.get('admin') === 'true'; // admin=true 확인


    // 전체 화면 전환 핸들러
    const handleFullscreenToggle = () => {
        if (!isFullscreen) {
            if (viewerRef.current.requestFullscreen) {
                viewerRef.current.requestFullscreen();
            } else if (viewerRef.current.webkitRequestFullscreen) {
                viewerRef.current.webkitRequestFullscreen();
            } else if (viewerRef.current.mozRequestFullScreen) {
                viewerRef.current.mozRequestFullScreen();
            } else if (viewerRef.current.msRequestFullscreen) {
                viewerRef.current.msRequestFullscreen();
            }
            setIsFullscreen(true);
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            }
            setIsFullscreen(false);
        }
    };

    const handleDeleteMessage = async (messageId) => {
        if (window.confirm('정말로 이 메시지를 삭제하시겠습니까?')) {
            console.log(messageId)
            try {
                await deleteMessage(workspaceId, messageId);
                alert('메시지가 삭제되었습니다.');
                handleClose(); // Modal 닫기
            } catch (error) {
                console.error('메시지 삭제 오류:', error);
                alert('메시지 삭제에 실패했습니다.');
            }
        }
    };


    const handleOpen = (message) => {
        // console.log(message)
        setSelectedMessage(message);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedMessage(null);
    };

    // 사용자 활동 감지 및 타이머 초기화
    const resetTimer = () => {
        clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
            startAutoScroll();
        }, 5000); // 10초 후 루핑 시작
    };

    // 자동 스크롤 시작
    const startAutoScroll = () => {
        timerRef.current = setInterval(() => {
            setActiveIndex((prevIndex) => (prevIndex + 1) % messages.length);
        }, 2000); // 2초마다 다음 카드로 이동
    };

    // 자동 스크롤 정지
    const stopAutoScroll = () => {
        clearInterval(timerRef.current);
    };

    useEffect(() => {
        // 모바일 기기 감지
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        if (isMobile) {
            window.location.href = window.location.href+"/write";
        }
    }, []);

    // Firestore에서 배경 이미지와 로고 가져오기
    useEffect(() => {
        const fetchWorkspaceSettings = async () => {
            try {
                const settings = await getWorkspaceSettings(workspaceId);
                if (settings) {
                    setBackgroundImage(settings.backgroundImage);
                    setLogo(settings.logo);
                    setWorkspaceName(settings.name);
                    setIsMessageBoardOpen(settings.isMessageBoardOpen);
                }
            } catch (error) {
                console.error('Error fetching workspace settings:', error);
            }
        };

        fetchWorkspaceSettings();
    }, [workspaceId]);

    useEffect(() => {
        if (workspaceId) {
            const unsubscribe = subscribeToMessages(workspaceId, (newMessages) => {
                // console.log(newMessages)
                setMessages(newMessages);
            });
            return async () => (await unsubscribe)();


        }
    }, [workspaceId]);

    useEffect(() => {
        previousMessageCount.current = messages.length;
        endOfMessagesRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);

    const handlers = useSwipeable({
        onSwipedLeft: (eventData) => {
            containerRef.current.scrollLeft += eventData.deltaX;
        },
        onSwipedRight: (eventData) => {
            containerRef.current.scrollLeft -= eventData.deltaX;
        },
        trackMouse: true,
    });

    const handleMouseDown = (e) => {
        isDragging.current = true;
        startX.current = e.pageX - containerRef.current.offsetLeft;
        scrollLeft.current = containerRef.current.scrollLeft;
        stopAutoScroll(); // 드래그 중 자동 스크롤 정지
    };

    const handleMouseMove = (e) => {
        if (!isDragging.current) return;
        const x = e.pageX - containerRef.current.offsetLeft;
        const walk = (startX.current - x) * 1.5;
        containerRef.current.scrollLeft = scrollLeft.current + walk;
    };

    const handleMouseUpOrLeave = () => {
        isDragging.current = false;
        resetTimer(); // 드래그 종료 후 타이머 재설정
    };

    useEffect(() => {
        resetTimer(); // 초기 타이머 설정

        return () => {
            stopAutoScroll(); // 컴포넌트 언마운트 시 정리
        };
    }, [resetTimer]);

    useEffect(() => {
        if (containerRef.current) {
            const cards = containerRef.current.children;
            if (cards[activeIndex]) {
                cards[activeIndex].scrollIntoView({
                    behavior: 'smooth',
                    inline: 'center',
                });
            }
        }
    }, [activeIndex]);

    return (
        <Box
            ref={viewerRef}
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                width: '100vw',
                backgroundImage: `url(${backgroundImage})`, // 배경 이미지 적용
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                fontFamily: 'GmarketSansMedium',
                cursor: isDragging.current ? 'grabbing' : 'grab',
                overflowX: 'scroll',
                overflowY: 'hidden',
                boxSizing: 'border-box',
                scrollbarWidth: 'none',
                '&::-webkit-scrollbar': { display: 'none' },
            }}
        >

            {/* 오른쪽 상단에 전체 화면 버튼 */}
            <IconButton
                onClick={handleFullscreenToggle}
                sx={{
                    position: 'absolute',
                    bottom: 16,
                    left: 16,
                    zIndex: 1000, // 버튼이 다른 요소 위에 표시되도록 설정
                    backgroundColor: 'rgba(255, 255, 255, 0.7)',
                    '&:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 0.9)',
                    },
                }}
            >
                {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </IconButton>

            {/* 로고 표시 */}
            {logo && (
                <Box
                    component="img"
                    src={logo}
                    alt="Workspace Logo"
                    sx={{
                        position: 'absolute',
                        top: '-5px',
                        left: 20,
                        width: 150,
                        height: 100,
                        objectFit: 'contain',
                    }}
                />
            )}

            {/* 상단 워크스페이스 이름 */}
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    width: '100%',
                    padding: '16px 0',
                    backgroundColor: 'rgba(0, 0, 0, 0.0)',
                    color: 'white',
                    textAlign: 'center',
                    zIndex: 10,
                }}
            >
                <Typography variant="h4" sx={{marginTop : 3, fontSize : '40px' , fontWeight: 700, fontFamily: 'GmarketSansMedium' , textAlign: 'center', // 텍스트를 가운데 정렬
                    color: 'white'}}>
                    ✦ {workspaceName} ✦
                    {/*<Typography*/}
                    {/*    component="span"*/}
                    {/*    sx={{*/}
                    {/*        fontWeight: 400, // 작게 표시*/}
                    {/*        fontSize: '18px', // 작은 폰트 크기*/}
                    {/*        marginLeft: 1, // 간격 추가*/}
                    {/*        fontFamily: 'GmarketSansMedium',*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    축하와 응원의 메시지를 전해주세요!*/}
                    {/*</Typography>*/}
                </Typography>

            </Box>

            <Box
                {...handlers}
                ref={containerRef}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUpOrLeave}
                onMouseLeave={handleMouseUpOrLeave}
                onTouchStart={handleMouseDown}
                onTouchMove={handleMouseMove}
                onTouchEnd={handleMouseUpOrLeave}
                sx={{
                    display: 'grid', // Grid 레이아웃 사용
                    gridTemplateColumns: `repeat(${Math.ceil(messages.length / 2)}, 300px)`, // 열 개수는 2줄에 맞게 설정
                    gridTemplateRows: 'repeat(2, 300px)', // 항상 2줄
                    justifyContent: messages.length <= 5 ? 'center' : 'flex-start',
                    flexWrap: 'nowrap', // 줄바꿈 방지
                    overflowX: 'auto', // 가로 스크롤 활성화
                    overflowY: 'hidden', // 데스크톱에서는 세로 스크롤 비활성화
                    userSelect: 'none', // 드래그 시 텍스트 선택 방지
                    width: '100%',
                    maxWidth: '1920px',
                    gap: 3, // 카드 간격
                    padding: 3,
                    '@media (max-width: 768px)': {
                        display: 'flex', // Flexbox로 전환
                        flexDirection: 'column', // 세로 배열
                        height: '100%', // 높이를 자동으로 조정
                        overflowX: 'hidden', // 가로 스크롤 비활성화
                        overflowY: 'auto', // 세로 스크롤 활성화
                    },
                    cursor: isDragging.current ? 'grabbing' : 'grab', // 드래그 시 커서 변경
                    '&::-webkit-scrollbar': {
                        display: 'none', // 스크롤바 숨김
                    },
                    scrollSnapType: 'x mandatory', // 스크롤 스냅 활성화
                }}
            >
                {messages.map((messageWrapper, index) => {
                    const duration = 3 + Math.random() * 3;
                    const amplitude = 5 + Math.random() * 10;
                    const delay = Math.random() * 2;

                    const message = messageWrapper.message; // message 객체 추출
                    // 카드 크기 계산: 5개 이하일 경우 화면 너비를 카드 개수에 맞게 분할
                    const cardWidth = messages.length <= 5 ? `calc(${100 / messages.length}% - 16px)` : 'calc(20% - 16px)';
                    // console.log(cardWidth)
                    return (
                        <Card
                            key={messageWrapper.id}
                            onClick={() => handleOpen({ ...message, id: messageWrapper.id })}
                            sx={{
                                height : '100%',
                                flex: `0 0 20%`, // 동적으로 카드 크기 설정
                                background: `url('/images/bg_card1.png')`,
                                backgroundSize: 'cover', // 이미지를 카드 크기에 맞춤
                                backgroundPosition: 'center', // 이미지를 중앙에 배치
                                color: '#000000',
                                borderRadius: '16px', // 카드 테두리 둥글게
                                overflow: 'hidden',
                                animation: `float ${duration}s ease-in-out ${delay}s infinite`,
                                '@keyframes float': {
                                    '0%, 100%': {transform: 'translateY(0px)'},
                                    '50%': {transform: `translateY(-${amplitude}px)`},
                                },
                                '@media (max-width: 768px)': {
                                    width: '100%', // 모바일에서 카드 너비를 부모 요소에 맞춤
                                    maxWidth: '100%',
                                    height: '100%',
                                },
                            }}
                        >
                            {/* 사진이 있는 경우 CardMedia 추가 */}
                            {message.image && (
                                <Box
                                    component="img"
                                    src={message.image}
                                    alt="Message Image"
                                    sx={{
                                        width: '100%',
                                        height: '150px',
                                        objectFit: 'contain',
                                        borderTopLeftRadius: '16px',
                                        borderTopRightRadius: '16px',
                                    }}
                                />
                            )}

                            <CardContent
                                sx={{
                                    flex: 1, // 남은 공간을 차지하도록 설정
                                    display: message.image ? 'block' : 'flex', // 이미지가 있을 경우 기본 블록 정렬
                                    flexDirection: message.image ? 'initial' : 'column', // 이미지는 세로 정렬 제외
                                    alignItems: message.image ? 'initial' : 'center', // 이미지 없을 때 가로 중앙 정렬
                                    justifyContent: message.image ? 'initial' : 'center', // 이미지 없을 때 세로 중앙 정렬
                                    height: '100%', // CardContent 전체 높이 사용
                                    textAlign: 'center', // 텍스트 중앙 정렬
                                    padding : 0,
                                }}
                            >
                                <Typography
                                    variant="body1"
                                    sx={{
                                        fontFamily: 'GmarketSansMedium',
                                        fontSize: '20px',
                                        whiteSpace: 'pre-line', // 줄바꿈 반영
                                        textAlign: 'center', // 텍스트 중앙 정렬
                                        userSelect: 'none', // 텍스트 선택 방지
                                        wordBreak: 'break-word', // 긴 단어 줄바꿈
                                        maxHeight: 'unset', // 높이 제한 제거

                                    }}
                                >
                                    {message.message || '메시지가 없습니다.' /* 기본 메시지 추가 */}
                                </Typography>

                                {message.affiliation || message.position ? (
                                    <Typography
                                        variant="caption"
                                        sx={{
                                            marginTop: 0,
                                        }}
                                    >
                                        {message.name || '작성자 이름 없음'} · {message.affiliation || '소속 없음'} · {message.position || '직책 없음'}
                                    </Typography>
                                ) : (
                                    <Typography
                                        variant="caption"
                                        sx={{
                                            marginTop: 1,
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {message.name || '작성자 이름 없음'}
                                    </Typography>
                                )}

                            </CardContent>
                        </Card>
                    );
                })}

                <div ref={endOfMessagesRef}/>
            </Box>

            {/* Modal for Enlarged View */}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Box
                    sx={{
                        borderRadius: '16px',
                        boxShadow: 24,
                        padding: 4,
                        width: '80%',
                        maxWidth: '600px',
                        height : '600px',
                        textAlign: 'center',
                        background: `url('/images/bg_card1.png')`,
                        backgroundSize: 'cover', // 이미지를 카드 크기에 맞춤
                        backgroundPosition: 'center', // 이미지를 중앙에 배치
                        color: '#000000',
                        display: 'flex', // Flexbox 사용
                        flexDirection: 'column', // 콘텐츠를 세로로 배치
                        alignItems: 'center', // 가로 중앙 정렬
                        justifyContent: 'center', // 세로 중앙 정렬
                        animation: open
                            ? 'flipIn 0.6s ease-in-out forwards'
                            : 'none',
                        '@keyframes flipIn': {
                            '0%': { transform: 'rotateY(-90deg)', opacity: 0.7 },
                            '50%': { transform: 'rotateY(0)', opacity: 0.8 },
                            '100%': { transform: 'rotateY(0)', opacity: 1 },
                        },
                    }}
                >
                    {selectedMessage && (
                        <>
                            {selectedMessage.image && (
                                <Box
                                    component="img"
                                    src={selectedMessage.image}
                                    alt="Message Image"
                                    sx={{
                                        width: '100%',
                                        height: '300px',
                                        objectFit: 'contain',
                                        borderRadius: '16px',
                                        marginBottom: 2,
                                    }}
                                />
                            )}
                            <Typography
                                id="modal-title"
                                variant="h6"
                                sx={{
                                    fontFamily: 'GmarketSansMedium',
                                    marginBottom: 2,
                                }}
                            >
                                {selectedMessage.message}
                            </Typography>
                            <Typography
                                id="modal-description"
                                variant="caption"
                                color="text.secondary"
                            >
                                {selectedMessage.name || '작성자 이름 없음'} · {selectedMessage.affiliation || '소속 없음'} · {selectedMessage.position || '직책 없음'}
                            </Typography>

                            {/* 삭제 아이콘 */}
                            {isAdmin && (
                                <IconButton
                                    onClick={() => handleDeleteMessage(selectedMessage.id)}
                                    sx={{
                                        position: 'absolute',
                                        top: '16px',
                                        right: '16px',
                                        backgroundColor: 'rgba(255, 0, 0, 0.1)',
                                        '&:hover': { backgroundColor: 'rgba(255, 0, 0, 0.2)' },
                                    }}
                                >
                                    <DeleteOutline color="error" />
                                </IconButton>
                            )}
                        </>
                    )}
                </Box>
            </Modal>

            {isMessageBoardOpen && (
                <div
                    style={{
                        position: 'absolute',
                        bottom: '30px',
                        right: '30px',
                        backgroundColor: 'white', // 박스 배경색
                        borderRadius: '16px', // 박스 모서리 둥글게
                        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // 그림자 효과
                        padding: '16px', // 내부 여백
                        display: 'flex',
                        flexDirection: 'column', // 세로 정렬
                        alignItems: 'center', // 가로 중앙 정렬
                        textAlign: 'center', // 텍스트 중앙 정렬
                        maxWidth: '150px', // 최대 너비 제한
                    }}
                >
                    <p
                        style={{
                            fontFamily: 'GmarketSansMedium, sans-serif', // 폰트 설정
                            fontSize: '14px', // 텍스트 크기
                            marginBottom: '8px', // QR 코드와 간격
                            color: '#333', // 텍스트 색상
                        }}
                    >
                        메시지를 전해주세요
                    </p>


                    <QRCodeCanvas
                        value={`${window.location.href}/write`}
                        size={120} // QR 코드 크기 조정
                        style={{
                            width: '100%',
                            height: 'auto',
                            borderRadius: '8px', // 선택사항: QR 코드도 모서리 둥글게
                        }}
                    />
                </div>
            )}


        </Box>
    );
};

export default GuestbookViewer;
