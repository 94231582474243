import React, { useState } from 'react';
import Carousel from 'react-material-ui-carousel';
import { AppBar, Toolbar, Button, Typography, Box, Container, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

const FullHeightCarousel = styled(Carousel)({
    height: '100vh',
    '& .carousel-slider': {
        height: '100%'
    },
    '& .slide': {
        height: '100%'
    }
});

const SlideImage = styled('img')({
    width: '100%',
    height: '100vh',
    objectFit: 'cover',
});

const Footer = styled(Box)({
    position: 'fixed',
    bottom: 0,
    width: '100%',
    padding: '20px',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    color: 'white',
    textAlign: 'center',
    zIndex: 1000
});

const CarouselContainer = styled(Box)({
    position: 'relative',
    height: '100vh',
    '& .MuiPaper-root': {
        zIndex: 1100
    }
});

const TextOverlay = styled(Box)({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    color: 'white',
    zIndex: 2,
    width: '100%',
    padding: '0 20px',
    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
});

const GradientOverlay = styled(Box)({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))',
    zIndex: 1,
});

const LandingPage = () => {
    const navigate = useNavigate();
    const [openDialog, setOpenDialog] = useState(false);
    const [contactForm, setContactForm] = useState({
        email: '',
        phone: '',
        message: ''
    });

    const handleContactSubmit = async () => {
        try {
            const webhookUrl = 'https://hooks.slack.com/services/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';
            
            const message = {
                text: "New Contact Request",
                blocks: [
                    {
                        type: "section",
                        text: {
                            type: "mrkdwn",
                            text: "*New Contact Request from EventWall*"
                        }
                    },
                    {
                        type: "section",
                        fields: [
                            {
                                type: "mrkdwn",
                                text: `*Email:*\n${contactForm.email}`
                            },
                            {
                                type: "mrkdwn",
                                text: `*Phone:*\n${contactForm.phone}`
                            }
                        ]
                    },
                    {
                        type: "section",
                        text: {
                            type: "mrkdwn",
                            text: `*Message:*\n${contactForm.message}`
                        }
                    }
                ]
            };

            await fetch(webhookUrl, {
                method: 'POST',
                body: JSON.stringify(message)
            });

            setOpenDialog(false);
            setContactForm({ email: '', phone: '', message: '' });
            alert('Thank you for your message. We will contact you soon.');
        } catch (error) {
            console.error('Error sending message:', error);
            alert('Failed to send message. Please try again later.');
        }
    };

    const handleInputChange = (e) => {
        setContactForm({
            ...contactForm,
            [e.target.name]: e.target.value
        });
    };

    const slides = [
        {
            url: "https://images.unsplash.com/photo-1524178232363-1fb2b075b655?q=80&w=2070",
            alt: "Event Scene 1"
        },
        {
            url: "https://images.unsplash.com/photo-1544531585-9847b68c8c86?q=80&w=2070",
            alt: "Event Scene 2"
        },
        {
            url: "https://images.unsplash.com/photo-1517245386807-bb43f82c33c4?q=80&w=2070",
            alt: "Event Scene 3"
        }
    ];

    return (
        <CarouselContainer>
            {/* 상단 바 */}
            <AppBar position="fixed" sx={{ backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
                <Container maxWidth="lg">
                    <Toolbar sx={{ justifyContent: 'space-between' }}>
                        <Typography 
                            variant="h6" 
                            sx={{ 
                                color: '#000', 
                                fontWeight: 'bold',
                                cursor: 'pointer'
                            }}
                        >
                            📝 EventWall 
                        </Typography>
                        <Box sx={{ display: 'flex', gap: 2 }}>
                             
                            <Button 
                                variant="contained" 
                                color="secondary"
                                onClick={() => setOpenDialog(true)}
                                sx={{
                                    borderRadius: '20px',
                                    padding: '8px 24px'
                                }}
                            >
                                Contact Us
                            </Button>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>

            <Box sx={{ 
                position: 'relative', 
                height: '100vh',
                zIndex: 1
            }}>
                <FullHeightCarousel
                    animation="slide"
                    interval={5000}
                    indicators={false}
                    swipe={true}
                >
                    {slides.map((slide, index) => (
                        <Box key={index} sx={{ position: 'relative' }}>
                            <SlideImage
                                src={slide.url}
                                alt={slide.alt}
                            />
                            <GradientOverlay />
                        </Box>
                    ))}
                </FullHeightCarousel>

                <TextOverlay>
                    <Typography 
                        variant="h2" 
                        sx={{ 
                            fontWeight: 'bold',
                            mb: 3,
                            fontSize: { xs: '2rem', md: '3.75rem' }
                        }}
                    >
                        Real-time Event Wall
                    </Typography>
                    <Typography 
                        variant="h5" 
                        sx={{ 
                            mb: 2,
                            fontSize: { xs: '1.2rem', md: '1.5rem' },
                            lineHeight: 1.8
                        }}
                    >
                        Interact in real-time at events and lectures
                    </Typography>
                    <Typography 
                        variant="body1" 
                        sx={{ 
                            fontSize: { xs: '1rem', md: '1.2rem' },
                            maxWidth: '800px',
                            margin: '0 auto',
                            lineHeight: 1.8
                        }}
                    >
                        Write questions, thoughts, and messages<br />
                        See them appear on screen in real-time
                    </Typography>
                </TextOverlay>
            </Box>

            <Footer>
                <Typography variant="body2">
                    COPYRIGHT (C) WIDEBRAIN Inc.
                </Typography>
            </Footer>

            <Dialog open={openDialog} onClose={() => setOpenDialog(false)} maxWidth="sm" fullWidth>
                <DialogTitle>Contact Us</DialogTitle>
                <DialogContent>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
                        <TextField
                            name="email"
                            label="Email"
                            type="email"
                            fullWidth
                            value={contactForm.email}
                            onChange={handleInputChange}
                        />
                        <TextField
                            name="phone"
                            label="Phone Number"
                            fullWidth
                            value={contactForm.phone}
                            onChange={handleInputChange}
                        />
                        <TextField
                            name="message"
                            label="Message"
                            multiline
                            rows={4}
                            fullWidth
                            value={contactForm.message}
                            onChange={handleInputChange}
                            placeholder="행사 내용 ,행사 날짜와 예상 참석 인원을 알려주세요."
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
                    <Button onClick={handleContactSubmit} variant="contained" color="primary">
                        Send
                    </Button>
                </DialogActions>
            </Dialog>
        </CarouselContainer>
    );
};

export default LandingPage; 