import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase'; // Firebase 초기화 파일
import { Box, Button, TextField, Typography, Container } from '@mui/material';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';


const AdminWorkspaceEdit = () => {
    const { workspaceId } = useParams(); // URL 파라미터로 workspaceId 가져오기
    const navigate = useNavigate();
    const [workspaceData, setWorkspaceData] = useState(null); // 워크스페이스 데이터 상태
    const [loading, setLoading] = useState(false); // 로딩 상태 관리
    const [logoFile, setLogoFile] = useState(null); // 로고 이미지 파일
    const [backgroundFile, setBackgroundFile] = useState(null); // 배경 이미지 파일

    useEffect(() => {
        // 워크스페이스 데이터 불러오기
        const fetchWorkspace = async () => {
            try {
                const workspaceRef = doc(db, 'workspaces', workspaceId);
                const workspaceSnap = await getDoc(workspaceRef);
                if (workspaceSnap.exists()) {
                    setWorkspaceData(workspaceSnap.data());
                } else {
                    alert('워크스페이스를 찾을 수 없습니다.');
                    navigate('/admin/workspaces');
                }
            } catch (error) {
                console.error('워크스페이스 불러오기 오류:', error);
            }
        };
        fetchWorkspace();
    }, [workspaceId, navigate]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setWorkspaceData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleFileChange = (e, type) => {
        if (type === 'logo') {
            setLogoFile(e.target.files[0]);
        } else if (type === 'background') {
            setBackgroundFile(e.target.files[0]);
        }
    };

    const uploadImage = async (file, folder) => {
        const storage = getStorage();
        const storageRef = ref(storage, `${folder}/${workspaceId}_${file.name}`);
        await uploadBytes(storageRef, file);
        return getDownloadURL(storageRef);
    };

    const handleSave = async () => {
        setLoading(true);
        try {
            const workspaceRef = doc(db, 'workspaces', workspaceId);

            // 이미지 업로드 및 URL 가져오기
            let logoURL = workspaceData.logo || null;
            let backgroundURL = workspaceData.backgroundImage || null;

            if (logoFile) {
                logoURL = await uploadImage(logoFile, 'workspaces/logos');
            }
            if (backgroundFile) {
                backgroundURL = await uploadImage(backgroundFile, 'workspaces/backgrounds');
            }

            // Firestore 업데이트
            await updateDoc(workspaceRef, {
                ...workspaceData,
                logo: logoURL,
                backgroundImage: backgroundURL,
            });

            alert('워크스페이스가 성공적으로 수정되었습니다.');
            navigate('/admin/workspaces'); // 수정 후 리스트 화면으로 이동
        } catch (error) {
            console.error('워크스페이스 수정 오류:', error);
            alert('수정 중 오류가 발생했습니다.');
        } finally {
            setLoading(false);
        }
    };

    if (!workspaceData) {
        return (
            <Container maxWidth="lg" sx={{ textAlign: 'center', marginTop: '50px' }}>
                <Typography variant="h5">워크스페이스 데이터를 불러오는 중...</Typography>
            </Container>
        );
    }

    return (
        <Container maxWidth="sm" sx={{ marginTop: '50px' }}>
            <Typography variant="h4" gutterBottom>
                워크스페이스 수정
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <TextField
                    label="워크스페이스 이름"
                    name="name"
                    value={workspaceData.name || ''}
                    onChange={handleInputChange}
                    fullWidth
                />
                {/* 추가 필드들 */}
                <TextField
                    label="설명"
                    name="description"
                    value={workspaceData.description || ''}
                    onChange={handleInputChange}
                    fullWidth
                />

                {/* 로고 업로드 */}
                <Typography>로고 업로드</Typography>
                <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleFileChange(e, 'logo')}
                />
                {workspaceData.logo && (
                    <img
                        src={workspaceData.logo}
                        alt="로고 미리보기"
                        style={{ width: '100%',objectFit: 'cover' }}
                    />
                )}

                {/* 배경 이미지 업로드 */}
                <Typography>배경 이미지 업로드</Typography>
                <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleFileChange(e, 'background')}
                />
                {workspaceData.backgroundImage && (
                    <img
                        src={workspaceData.backgroundImage}
                        alt="배경 미리보기"
                        style={{ width: '100%',objectFit: 'cover' }}
                    />
                )}


                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSave}
                    disabled={loading}
                >
                    {loading ? '저장 중...' : '저장'}
                </Button>
                <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => navigate('/admin/workspaces')}
                >
                    취소
                </Button>
            </Box>
        </Container>
    );
};

export default AdminWorkspaceEdit;
