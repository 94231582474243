// AdminWorkspaceCreate.js
import React, { useState } from 'react';
import {Container, TextField, Button, Typography, Box, InputLabel} from '@mui/material';
import {createWorkspace, uploadImage} from './firebaseService';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import {storage} from "../firebase"; // Firestore에 워크스페이스 추가하는 함수 import

const AdminWorkspaceCreate = () => {
    const [workspaceName, setWorkspaceName] = useState('');
    const [workspaceDescription, setWorkspaceDescription] = useState('');
    const [backgroundImageFile, setBackgroundImageFile] = useState(null);
    const [logoImageFile, setLogoImageFile] = useState(null);
    const [backgroundImage, setBackgroundImage] = useState(null);
    const [logoImage, setLogoImage] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isUploading, setIsUploading] = useState(false);
    const [backgroundPreview, setBackgroundPreview] = useState(null);
    const [logoPreview, setLogoPreview] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);

    const handleCreateWorkspace = async () => {
        setSuccessMessage('');
        setErrorMessage('');

        if (!workspaceName.trim()) {
            setErrorMessage('워크스페이스 이름을 입력해주세요.');
            return;
        }

        try {
            setIsUploading(true);
            setUploadProgress(0);

            // Firebase Storage에 이미지 업로드
            const backgroundImageURL = backgroundImageFile
                ? await uploadImageWithProgress(backgroundImageFile, 'workspace/backgrounds')
                : null;
            const logoImageURL = logoImageFile
                ? await uploadImageWithProgress(logoImageFile, 'workspace/logos')
                : null;

            const workspaceData = {
                name: workspaceName,
                description: workspaceDescription,
                backgroundImage: backgroundImageURL,
                logo: logoImageURL,
            };

            const id = await createWorkspace(workspaceData);
            setSuccessMessage(`워크스페이스가 생성되었습니다 (ID: ${id})`);
            setWorkspaceName('');
            setWorkspaceDescription('');
            setBackgroundImage(null);
            setLogoImage(null);
            setBackgroundImageFile(null)
            setLogoImageFile(null)
            setLogoPreview('')
            setBackgroundPreview('')
        } catch (error) {
            setErrorMessage('워크스페이스 생성 중 오류가 발생했습니다.');
            console.error('Error creating workspace:', error);
        }
        finally {
            setIsUploading(false);
        }
    };

    const uploadImageWithProgress = (file, folder) => {
        return new Promise((resolve, reject) => {
            const storageRef = ref(storage, `${folder}/${file.name}`);
            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on(
                'state_changed',
                (snapshot) => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    setUploadProgress(progress);
                },
                (error) => {
                    console.error('Upload error:', error);
                    reject(error);
                },
                async () => {
                    const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                    resolve(downloadURL);
                }
            );
        });
    };

    const handleFileChange = (e, setFile, setPreview) => {
        const file = e.target.files[0];
        setFile(file);

        // 이미지 미리보기 설정
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview(reader.result);
            };
            reader.readAsDataURL(file);
        } else {
            setPreview(null);
        }
    };

    return (
        <Container maxWidth="sm" style={{ marginTop: '50px' }}>
            <Typography variant="h4" gutterBottom>
                워크스페이스 생성
            </Typography>
            {successMessage && (
                <Typography variant="body1" color="success.main" gutterBottom>
                    {successMessage}
                </Typography>
            )}
            {errorMessage && (
                <Typography variant="body1" color="error.main" gutterBottom>
                    {errorMessage}
                </Typography>
            )}
            <Box component="form" noValidate autoComplete="off">
                <TextField
                    label="워크스페이스 이름"
                    variant="outlined"
                    fullWidth
                    value={workspaceName}
                    onChange={(e) => setWorkspaceName(e.target.value)}
                    style={{ marginBottom: '20px' }}
                />
                <TextField
                    label="워크스페이스 설명"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={4}
                    value={workspaceDescription}
                    onChange={(e) => setWorkspaceDescription(e.target.value)}
                    style={{ marginBottom: '20px' }}
                />

                {/* 배경 이미지 업로드 */}
                <Box display="flex" flexDirection="column" alignItems="center" gap={2} mb={2}>
                    <input
                        accept="image/*"
                        style={{ display: 'none' }}
                        id="background-upload"
                        type="file"
                        onChange={(e) => handleFileChange(e, setBackgroundImageFile,setBackgroundPreview)}
                    />
                    <label htmlFor="background-upload">
                        <Button variant="contained" component="span">
                            배경 이미지 선택
                        </Button>
                    </label>
                    {backgroundImageFile && (
                        <Typography variant="body2" color="textSecondary">
                            선택된 배경 이미지: {backgroundImageFile.name}
                        </Typography>
                    )}
                    {backgroundPreview && (
                        <Box mt={2}>
                            <img src={backgroundPreview} alt="배경 이미지 미리보기" style={{ width: '100%', maxHeight: '200px', objectFit: 'cover' }} />
                        </Box>
                    )}
                </Box>

                {/* 로고 이미지 업로드 */}
                <Box display="flex" flexDirection="column" alignItems="center" gap={2} mb={2}>
                    <input
                        accept="image/*"
                        style={{ display: 'none' }}
                        id="logo-upload"
                        type="file"
                        onChange={(e) => handleFileChange(e, setLogoImageFile,setLogoPreview)}
                    />
                    <label htmlFor="logo-upload">
                        <Button variant="contained" component="span">
                            로고 이미지 선택
                        </Button>
                    </label>
                    {logoImageFile && (
                        <Typography variant="body2" color="textSecondary">
                            선택된 로고 이미지: {logoImageFile.name}
                        </Typography>
                    )}
                    {logoPreview && (
                        <Box mt={2}>
                            <img src={logoPreview} alt="로고 이미지 미리보기" style={{ width: '100%', maxHeight: '100px', objectFit: 'contain' }} />
                        </Box>
                    )}
                </Box>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleCreateWorkspace}
                    fullWidth
                    disabled={isUploading}
                >
                    {isUploading ? '업로드 중...' : '워크스페이스 생성'}
                </Button>
            </Box>
        </Container>
    );
};

export default AdminWorkspaceCreate;
