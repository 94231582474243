// firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyDy4UvlUSAydpqDEHp42zS1chfRqg5TyqE",
    authDomain: "realtime-guestbook.firebaseapp.com",
    projectId: "realtime-guestbook",
    storageBucket: "realtime-guestbook.appspot.com",
    messagingSenderId: "848498495535",
    appId: "1:848498495535:web:e6a9deb5b78e6958c636da",
    measurementId: "G-BJNTDB27EB"
};

// Firebase 앱 초기화
const app = initializeApp(firebaseConfig);

// Firestore와 Storage 인스턴스 초기화
const db = getFirestore(app);
const storage = getStorage(app);

export { db, storage };
