import React from 'react';
import {collection, doc, getDoc, getDocs} from 'firebase/firestore';
import { db } from '../firebase'; // Firebase 초기화 파일
import * as XLSX from 'xlsx';
import { Button } from '@mui/material';

const ExportMessagesToExcel = ({ workspaceId }) => {
    const handleExport = async () => {
        try {
            // Firestore에서 워크스페이스 데이터 가져오기
            const workspaceDocRef = doc(db, 'workspaces', workspaceId);
            const workspaceDoc = await getDoc(workspaceDocRef);
            const workspaceName = workspaceDoc.exists() ? workspaceDoc.data().name : '알 수 없음';

            // Firestore에서 메시지 데이터 가져오기
            const messagesRef = collection(db, `workspaces/${workspaceId}/messages`);
            const snapshot = await getDocs(messagesRef);
            const messages = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            if (messages.length === 0) {
                alert('메시지가 없습니다.');
                return;
            }

            // console.log(snapshot)
            // Excel 데이터 구조 생성
            const data = messages.map((message, index) => ({
                '번호': index + 1,
                '이름': message.message?.name || '없음',
                '소속': message.message?.affiliation || '없음',
                '직책': message.message?.position || '없음',
                '메시지': message.message?.message || '없음',
                '이미지 URL': message.message?.image
                    ? { t: 's', v: '이미지 보기', l: { Target: message.message.image } }
                    : '없음', // 링크로 추가
                '작성 시간': new Date(message.message?.timestamp?.seconds * 1000).toLocaleString(),
            }));

            // Excel 워크북 생성
            const worksheet = XLSX.utils.json_to_sheet(data);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Messages');

            // Excel 파일 다운로드
            XLSX.writeFile(workbook, `${workspaceName}_Messages.xlsx`);
        } catch (error) {
            console.error('Excel 생성 중 오류 발생:', error);
            alert('Excel 파일 생성에 실패했습니다.');
        }
    };

    return (
        <Button variant="outlined"
                color="secondary" onClick={handleExport}>
            메시지 엑셀로 내보내기
        </Button>
    );
};

export default ExportMessagesToExcel;
